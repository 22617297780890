<template>
  <div>
    <el-form label-width="110px" label-position="top">
      <el-form-item>
        <el-select v-model.number="stateId" placeholder="请选择">
          <el-option label="登记" :value="1"></el-option>
          <el-option label="运行中" :value="2"></el-option>
          <el-option label="完工" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-row class="bt"><el-button @click="$parent.handleClose()">取消</el-button> <el-button type="primary" @click="updateRunState">修改</el-button></el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updateNodeState } from '@/api/Node.js'
export default {
  data() {
    return { stateId: 1 }
  },
  props: {
    nodeId: {
      type: Number,
    },
  },
  methods: {
    async updateRunState() {
      const { data: res } = await updateNodeState(this.nodeId, this.stateId)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })

      this.$parent.handleClose()
      // 因为element Ui 多层组件嵌套  所以$parent 是找到了 elementUi的组件
      this.$parent.$parent.findAllNodeByHostFun()
    },
  },
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss">
.el-select {
  width: 300px;
}
.bt {
  text-align: right;
}
</style>
