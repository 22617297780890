<template>
  <div class="nodeTransfer">
    <el-row>
      <!-- <el-select class="fl" v-model="hostId" filterable placeholder="选择主机查看节点" @change="changeHost">
        <el-option v-for="item in hostList" :key="item.pkHostId" :label="item.hostNumber" :value="item.pkHostId"> </el-option>
      </el-select> -->
      <el-select class="fr" v-model="taskId" filterable placeholder="选择任务" @change="changeTask">
        <el-option v-for="item in hostTaskList" :key="item.pkTaskId" :label="item.projectName" :value="item.pkTaskId"> </el-option>
      </el-select>
    </el-row>

    <el-transfer
      v-model="taskNodeList"
      v-loading="loading"
      :titles="['主机:' + hostNumber, projectName]"
      :props="{
        key: 'pkNodeId',
        label: 'nodeNumber',
        disabled: 'disabled',
      }"
      :button-texts="['迁出', '迁入']"
      :data="hostNodeList"
      filterable
      @change="handleChange"
    ></el-transfer>
  </div>
</template>

<script>
import { findHostByUnit, findAllHost, findHostByUserIdHost, findHostById } from '@/api/Host.js'
import { findNode2List, distributionNodeToTaskV2, removeNode2 } from '@/api/Node.js'

import { findTaskByHostId, findTaskByTaskId } from '@/api/Task.js'
export default {
  data() {
    return {
      hostNodeList: [],
      taskNodeList: [],
      hostNumber: '',
      projectName: '',
      hostList: [],
      taskId: null,
      hostTaskList: [],
      currentPage: 0,
      pageSize: 1000,
      loading: true,
    }
  },
  props: {
    hostId: {
      type: Number,
    },
  },
  methods: {
    async findHostByUser() {
      if (this.$store.state.power === 4) {
        return await findAllHost(0, 99999)
      }
      if (this.$store.state.power === 3) {
        return await findHostByUnit(this.$store.state.unitId, 0, 99999)
      }
      if (this.$store.state.power === 2) {
        return await findHostByUserIdHost(this.$store.state.userId, 0, 99999)
      }
    },
    // 根据不同用户身份查询用户主机
    async findAllHostByUserFun() {
      const { data: res } = await this.findHostByUser()
      this.hostList = res.data.list
      this.hostList.forEach((item) => {
        item.companyName = item.company.companyName
      })
      this.hostId = this.hostList[0].pkHostId
      this.hostNumber = this.hostList[0].hostNumber
    },

    async findAllProjectFun() {
      this.dataSource = []
      const { data: res } = await findTaskByHostId(null, this.hostId, this.currentPage, this.pageSize)
      console.log('任务数据', res.data.list)
      this.hostTaskList = res.data.list
      this.hostTaskList.forEach((item) => {
        item.runningState = item.state.runningState
      })

      console.log(this.hostTaskList)

      if (this.hostTaskList.length <= 0) {
        return
      } else {
        this.taskId = this.hostTaskList[0].pkTaskId
        this.projectName = this.hostTaskList[0].projectName
        this.projectName.length > 12 ? (this.projectName = this.projectName.slice(0, 12) + '...') : this.projectName
      }
    },

    async findAllNodeByHostFun() {
      const { data: res } = await findNode2List(this.hostId, null, null, this.currentPage, this.pageSize)
      this.hostNodeList = res.data.list
      // 节点绑定任务 并且和选中的任务ID 不一致时 设置为禁用状态
      this.hostNodeList.forEach((item) => {
        if (item.task && item.task.pkTaskId !== this.taskId) {
          console.log(item.task, this.taskId)
          item.disabled = true
        } else {
          item.disabled = false
        }
      })
    },

    async findAllNodeByTaskFun() {
      if (!this.taskId) {
        this.$message({
          message: '该主机下无任务，请先创建任务！',
          type: 'warning',
        })
        return
      }
      const { data: res } = await findNode2List(null, this.taskId, null, this.currentPage, this.pageSize)
      const taskNodeList = []
      if (!res.data || res.data.length === 0) {
        this.taskNodeList = taskNodeList
        return
      } else {
        res.data.list.forEach((item) => {
          taskNodeList.push(item.pkNodeId)
        })
        this.taskNodeList = taskNodeList
      }
    },

    async findHostByIdFun() {
      const { data: res } = await findHostById(this.hostId)
      console.log('主机信息', res)
      this.hostNumber = res.data.list[0].hostNumber
    },
    async findTaskByTaskIdFun(e) {
      const { data: res } = await findTaskByTaskId(e)
      console.log('任务信息', res)
      this.projectName = res.data.list[0].projectName
      this.projectName.length > 12 ? (this.projectName = this.projectName.slice(0, 12) + '...') : this.projectName
    },

    async init() {
      await this.findAllProjectFun()
      await this.findAllNodeByHostFun()
      await this.findAllNodeByTaskFun()

      this.loading = false
    },

    async distributionNodeToTaskFun(nodeIds) {
      const { data: res } = await distributionNodeToTaskV2(this.taskId, nodeIds.join())
      console.log('迁移', res.data)
      // 迁移完成以后查询
      await this.findAllNodeByTaskFun()
      if (res.data.length === 0) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: '所有节点添加成功',
          type: 'success',
        })
        return
      } else {
        console.log(res.data.length)
        res.data.forEach((item, index) => {
          console.log(item)
          this.$message({
            dangerouslyUseHTMLString: true,
            offset: 80 * index,
            message: '节点<span style="color:green"> ' + item.nodeNumber + ' </span>加入失败,可能在其他任务中，请改节点状态为完工后重试！',
            type: 'error',
            duration: 1000,
          })
        })
      }

      await this.findAllNodeByTaskFun()
    },

    async removeNodeFun(taskId, nodeIds) {
      const { data: res } = await removeNode2(taskId, nodeIds)
      console.log('123', res)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })

      await this.findAllNodeByHostFun()
      await this.findAllNodeByTaskFun()

      this.loading = false
    },
    handleChange(value, direction, movedKeys) {
      this.loading = true
      if (direction === 'right') {
        this.distributionNodeToTaskFun(movedKeys)
        this.findAllNodeByTaskFun().then(() => {
          this.loading = false
          this.$parent.$parent.findAllNodeByHostFun()
        })
      } else if (direction === 'left') {
        console.log(this.taskId, movedKeys.join(','))
        this.removeNodeFun(this.taskId, movedKeys.join(','))
      }
    },
    changeHost(e) {
      this.hostTaskList = []
      console.log(e)
      this.findHostByIdFun(e).then(() => {
        this.findAllNodeByHostFun()
        this.findAllProjectFun()
      })
    },
    changeTask(e) {
      this.findTaskByTaskIdFun(e)
    },
  },
  watch: {
    taskId(e) {
      console.log(e)
      if (this.taskId) {
        console.log(this.taskId)
        console.log('执行')
        // this.findAllNodeByTaskFun()

        this.findAllNodeByHostFun()
        this.findAllNodeByTaskFun()
      }
    },
  },
  created() {
    this.findHostByIdFun()
    this.init()

    // this.findHostByIdFun()
    // this.findAllNodeByHostFun()

    // this.findAllProjectFun()

    // this.findAllNodeByTaskFun()

    // this.loading = false
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.nodeTransfer .el-row {
  // width: calc(100% - 10px);
  width: 844px;
  margin-bottom: 10px;

  .el-select {
    width: 300px;
  }
}

// .nodeTransfer ::v-deep .el-transfer__button:first-child {
//   margin-bottom: 10px;
//   display: none;
// }

// .nodeTransfer ::v-deep .el-transfer-panel__header:nth-child(2) .el-checkbox__input {
//   display: none !important;
// // }
// .nodeTransfer ::v-deep .el-transfer-panel:nth-child(3) .el-transfer-panel__header .el-checkbox__input {
//   display: none !important;
// }

.nodeTransfer ::v-deep .el-transfer-panel {
  width: 300px;
  height: 500px;

  .el-transfer-panel__header {
    width: 100%;
    overflow: hidden;

    .el-checkbox__label {
      width: 120px;
      //   display: inline-block;
      //   margin-top: 2px;
      //   overflow: hidden;
      //
    }
  }

  .el-checkbox-group.el-transfer-panel__list {
    height: 500px;
    padding-bottom: 50px;
  }
}
</style>
